import FEEDBACK from '../mixins/FEEDBACK.js'
// import recordings from '../store/recordings'

export class Plan {

  static allRecordings = {}

  constructor (onSetStep, onEndPlan, playComputer, queue, playStack) {
    if (onSetStep && onEndPlan) this.hookup(onSetStep, onEndPlan, playComputer, queue, playStack)
    this.name = 'Plan'
    this.TS = false // new Date().getTime()
    this.completed = false

    this.fields = []
    this.max = 0
    this.recordings = []
    this.fromURL = false
    this.treeNode = false
    this.index = -1 // RENAME: step
    this.active = { // RENAME: currentStep or stepData
      id: false,
      play: () => console.log('play attempt'),
      label: '', // used by regionLabelCalc
      /* .start, .end */
    }
    this.promptsPropertyName = ''
    this.replaysPropertyName = ''
    this.action = 'ready'
    this.source = 'old'
  }

  static onSetStep = function () {
    console.log('NOT DEFINED: onSetStep ')
  }

  static onEndPlan = function () {
    console.log('NOT DEFINED: onSetStep ')
  }

  static playComputer = function () {
    console.log('NOT DEFINED: onSetStep ')
  }

  static queue = function () {
    console.log('NOT DEFINED: onSetStep ')
  }

  static playStack = function () {
    console.log('NOT DEFINED: onSetStep ')
  }

  hookup (onSetStep, onEndPlan, playComputer, queue, playStack) {
    Plan.onSetStep = onSetStep
    Plan.onEndPlan = onEndPlan
    Plan.playComputer = playComputer
    Plan.queue = queue
    Plan.playStack = playStack
  }

  byTS (match) {
    return Plan.allRecordings[match]
  }

  activate () {
    let key = this.fields[this.index]
    this.active = Plan.onSetStep(key)
  }

  setOrder (order) {
    if (order < this.max) {
      this.index = order
      // callback set activeRegion
      this.activate()
    } else {
      this.completed = true
      // call end callback
      Plan.onEndPlan()
    }
  }

  playPractice (planTS) {
    let vue = this
    if (this.source === 'new') {
      let scriptSteps = vue.data['scriptSteps'] || this.state.scripts['scriptSteps']
      let recordings = this.state.recordings.byPlan[planTS]
      scriptSteps.forEach(sstep => {
        if (vue.data[vue.replaysPropertyName].length > 0) {

          vue.data[vue.replaysPropertyName].forEach(act => {
            if (sstep.field) {
              // vue.pushSpeach(act, sstep, sstep.field.recordings[planTS])
              debugger
              vue.pushSpeach(act, sstep, recordings.keys[sstep.key].TS)
            }
            else {
              vue.pushSpeach(act, sstep, false)
            }
          })
        }
      })
    } else {
      // older drill?
      this.recordings.forEach((rec) => {

        if (rec.regionID) {
          Plan.queue.push(rec.regionID)
          Plan.queue.push(rec.TS)
        } else {
          let step = rec.fromPlanStep

          let field = vue.fields[step]
          if (vue.data[vue.replaysPropertyName].length > 0) {

            vue.data[vue.replaysPropertyName].forEach(act => {
              vue.pushSpeach(act, field, rec.TS)
            })
          } else {

            Plan.queue.push(field.question)
            Plan.queue.push(rec.TS)
            Plan.queue.push(field.answer)
          }
        }
      })
    }
    Plan.playStack()
  }

  playFields () {
    let vue = this
    this.fields.forEach((field) => {
      if (vue.data[this.replaysPropertyName].length > 0) {

        vue.data[this.replaysPropertyName].forEach(act => {
          this.pushSpeach(act, field, false)
        })
      } else {
        Plan.queue.push(field.question)
        Plan.queue.push(field.answer)
      }
    })

    Plan.playStack()
  }

}

export class PlanWS extends Plan {
  constructor (keys) {
    super()
    this.name = 'PlanWS'

    this.TS = new Date().getTime()
    Plan.allRecordings[this.TS] = this

    this.fields = keys
    this.max = keys.length
  }
}

export class PlanURL extends Plan {
  /*
  BAD!  directly references data['fields'] and now that's coming from vuex
  problem tried to solve: initializiation comes before data is needed
  want multiple plans able to work
  therefore, maybe only create when ready?

   */
  constructor (data, state, fieldsFld, promptsFld, replaysFld, customText1Fld, customText2Fld) {
    super()
    this.name = 'PlanURL'

    this.data = data
    this.state = state
    this.TS = new Date().getTime()
    Plan.allRecordings[this.TS] = this

    this.fromURL = true
    debugger
    this.fields = data[fieldsFld] || state.scripts[fieldsFld]
    this.max = this.fields.length
    this.createActives()
    this.promptsPropertyName = promptsFld
    this.replaysPropertyName = replaysFld
    this.customText1 = customText1Fld
    this.customText2 = customText2Fld
    this.source = 'new'
  }

  playBeforeInput (field) {
    // console.log(this.data.aaaa, this.data.prompts)
    console.log('this.promptsPropertyName in playBeforeInput', this.promptsPropertyName, this.data[this.promptsPropertyName].length)
    this.data[this.promptsPropertyName].some(act => {
      if (act.value === FEEDBACK.COMMANDS.autoRecord || act.value === FEEDBACK.COMMANDS.record) {
        this.action = act.value
        return true
      }
      this.pushSpeach(act, field, false)
    })
    Plan.playStack()
    // Plan.playComputer(field.question)
  }

  playAfterInput (field) {

    let afterFeedback = false
    let anythingPlaying = false
    this.data[this.promptsPropertyName].some(act => {
      if (afterFeedback) {
        anythingPlaying = true
        this.pushSpeach(act, field, false)
      }
      if (act.value === FEEDBACK.COMMANDS.autoRecord || act.value === FEEDBACK.COMMANDS.record) {
        afterFeedback = true
      }
    })
    if (anythingPlaying) {
      // Plan.playStack()
    }
    // Plan.playComputer(field.question)
  }

  setOrder (order) {
    // play end of last set
    if (order > 0) {
      let field = this.fields[this.index]
      this.playAfterInput(field)
    }
    if (order < this.max) {
      this.index = order
      // callback set activeRegion
      this.activate()
    } else {
      this.completed = true
      // call end callback
      Plan.onEndPlan()
    }
  }

  pushSpeach (act, field, TS) {
    switch (act.value) {
      case FEEDBACK.COMMANDS.playPrompt: {
        Plan.queue.push(field.text)
        break
      }
      case FEEDBACK.COMMANDS.playVariable: {
        if (TS) Plan.queue.push(TS)
        break
      }
      case FEEDBACK.COMMANDS.counter: {
        Plan.queue.push((field.index).toString())
        break
      }
      case FEEDBACK.COMMANDS.question: {

        Plan.queue.push(field.question)
        break
      }
      case FEEDBACK.COMMANDS.hint: {
        Plan.queue.push(field.hint)
        break
      }
      case FEEDBACK.COMMANDS.hintDelayed: {
        // TODO: put on a timer etc.
        Plan.queue.push(field.hint)
        break
      }
      case FEEDBACK.COMMANDS.autoRecord:
      case FEEDBACK.COMMANDS.playPractice: {
        if (TS) {
          Plan.queue.push(TS)
        } else {
          Plan.queue.push('No practice recorded')
        }
        break
      }
      case FEEDBACK.COMMANDS.answer: {
        Plan.queue.push(field.answer)
        break
      }
      case FEEDBACK.COMMANDS.beep: {
        Plan.queue.push({ beep: 'please' })
        break
      }
      case FEEDBACK.COMMANDS.beep2: {
        Plan.queue.push({ beep2: 'please' })
        break
      }
      case FEEDBACK.COMMANDS.beep2x: {
        Plan.queue.push({ beep2x: 'please' })
        break
      }
      case FEEDBACK.COMMANDS.custom1: {
        Plan.queue.push(this.data[this.customText1])
        break
      }
      case FEEDBACK.COMMANDS.custom2: {
        Plan.queue.push(this.data[this.customText2])
        break
      }

      default: {
        console.log('Not coded: ' + act.value)

        break
      }
    }

  }

  createActives () {
    let vue = this
    this.actives = this.fields.map((field) => {
      let vue2 = vue
      return {
        field,
        id: field.index,
        label: field.question,
        hint: field.hint,
        // answer: field.answer,
        practices: [],
        play: function () {

          vue2.playBeforeInput(field)
        },
      }
    })

  }

  activate () {
    this.active = this.actives[this.index]
  }

  /*
          this.fields.forEach(field => {
          lp.fields.push({
            id: field.index,
            label: field.question,
            hint: field.hint,
            answer: field.answer,
            practices: [],
            play: function () {
              vue.playComputer(field.question)
            },
          })
        })

   */

}

/*
export class PlanStep {
  constructor () {
    this.junk = ''
  }
}
*/

