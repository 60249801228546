import { Plan } from './Plan'
// import { Menu, MenuPractice, MenuRegion, MenuAction } from '../src/mixins/menu'
import { MenuPractice } from './menu'

export const LessonPlan = {
  data () {
    return {
      lessonPlan: false,
      lessonBeingRecorded: false,
      timestamps: [],
      recordings: [],

      urlTitle: 'Your URL wants to know...',
      activeField: 1,
    }
  },
  computed: {

  },
  methods: {

    setup_plan (store) {
      /*
* does not do recording itself, but preps a loop already in  lessons.list
* */

      this.leftSlideBarOpen = false
      this.lessonBeingRecorded = false

      this.ensureIOSunlocked()
      let vue = this
      // vue.activeRegion = false

      let lessonTS = vue.lessonPlan.TS
      // add menu items
      let handler = function () {
        vue.lessonPlayPractice(lessonTS)
      }

      let menuItem = new MenuPractice(
        this.dated(this.lessonPlan.TS),
        handler,
        this.lessonPlan.TS,
        true, // ticks
        true, // as a lesson
      )
      // this.lessonPlan.treeNode = this.menu.append('practicesNode', menuItem, true)
      this.lessonPlan.treeNode = {}

      // planTS

      let items = {
        label: this.dated(lessonTS),
        TS: lessonTS,
        menuItem,
        handler,
        treeNode: false,
        recordings: [],
        keys: {},
      }
      store.dispatch('startPlan', items)
      // this.practices.URL.push(items)

      this.lessonSetOrder(0)

      this.lessonPlan.active.play()

    },

    lessonSetOrder (order) {
      this.lessonPlan.setOrder(order)
    },
    planSetStep (key) {
      // place holders so other configs work
    },
    planEndPlan () {
      // this needs to get hooked up, or for now called at end of onNewRecording
    },
    regionLabelCalc (region) {
      return region.label ||
        `${Math.round(region.start * 10) / 10} for ${Math.round((region.end - region.start) * 10) / 10} secs`
    },
  },
  created () {
    this.lessonPlan = new Plan(this.planSetStep, this.planEndPlan, this.playComputer, this.playQueue, this.playStack)
  },

}
