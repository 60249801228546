let KEYS = ['counter',
  'question',
  'hint',
  'hintDelayed',
  'answer',
  'beep',
  'beep2',
  'beep2x',
  'autoRecord',
  'record',
  'custom1',
  'playPractice',
  'custom2',
  'playPrompt',
  'playVariable',
]
let VALUES = ['Say Counter',
  'Say Question',
  'Say Hint',
  'Say Hint', // TODO:  after 3 seconds no answer (include before recording)',
  'Say Answer',
  'Beep',
  'Beep Again',
  'Beep Twice',
  'Immediately record practice',
  'Record Practice on click',
  'Say Custom 1',
  'Play Practice',
  'Say Custom 2',
  'Play Prompt',
  'Play Variable',
]
let COMMANDS = {
  'counter': 'counter',
  'question': 'question',
  'hint': 'hint',
  'hintDelayed': 'hintDelayed',
  'answer': 'answer',
  'beep': 'beep',
  'beep2': 'beep2',
  'beep2x': 'beep2x',
  'autoRecord': 'autoRecord',
  'record': 'record',
  'custom1': 'custom1',
  'playPractice': 'playPractice',
  'custom2': 'custom2',
  'playPrompt': 'playPrompt',
  'playVariable': 'playVariable',
}
// let promptN = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12]
let promptOptions = [
  { label: VALUES[0], value: KEYS[0] },
  { label: VALUES[1], value: KEYS[1] },
  { label: VALUES[2], value: KEYS[2] },
  { label: VALUES[3], value: KEYS[3] },
  { label: VALUES[4], value: KEYS[4] },
  { label: VALUES[5], value: KEYS[5] },
  { label: VALUES[6], value: KEYS[6] },
  { label: VALUES[7], value: KEYS[7] },
  { label: VALUES[8], value: KEYS[8] },
  { label: VALUES[9], value: KEYS[9] },
  { label: VALUES[10], value: KEYS[10] },
  { label: VALUES[12], value: KEYS[12] },
]
// let playbackN = [0, 1, 5, 2, 3, 4, 10, 11]
let playbackOptions = [
  { label: VALUES[0], value: KEYS[0] },
  { label: VALUES[1], value: KEYS[1] },
  { label: VALUES[5], value: KEYS[5] },
  { label: VALUES[2], value: KEYS[2] },
  { label: VALUES[3], value: KEYS[3] },
  { label: VALUES[4], value: KEYS[4] },
  { label: VALUES[10], value: KEYS[10] },
  { label: VALUES[11], value: KEYS[11] },
  { label: VALUES[12], value: KEYS[12] },
]

let FEEDBACK = {
  COMMANDS,
  promptOptions,
  playbackOptions,
}
export default FEEDBACK
/*
let old = {
  'prompt': {
    'counter': 'Say Counter',
    'question': 'Say Question',
    'hint': 'Say Hint',
    'hintDelayed': 'Say Hint after 3 seconds no answer',
    'answer': 'Say Answer',
    'beep': 'Beep',
    'beep2': 'Beep Again',
    'beep2x': 'Beep Twice',
    'autoRecord': 'Immediately record practice',
    'record': 'Record Practice on click',
    'custom1': 'Say Custom1',
  },
  'playback': {
    'counter': 'Say Counter',
    'question': 'Say Question',
    'beep': 'Beep',
    'hint': 'Say Hint',
    'hintDelayed': 'Say Hint after 3 seconds no answer',
    'answer': 'Say Answer',
    'custom1': 'Say Custom1',
    'playPractice': 'Play Practice',
  },
  'commands': {
    'counter': 'Say Counter',
    'question': 'Say Question',
    'hint': 'Say Hint',
    'hintDelayed': 'Say Hint after 3 seconds no answer',
    'answer': 'Say Answer',
    'beep': 'Beep',
    'beep2': 'Beep Again',
    'beep2x': 'Beep Twice',
    'autoRecord': 'Immediately record practice',
    'record': 'Record Practice on click',
    'custom1': 'Say Custom1',
    'playPractice': 'Play Practice',
  },
}

let KEYS = Object.keys(old.commands)
let VALUES = Object.values(old.commands)

console.log('let KEYS = ' + JSON.stringify(KEYS))
console.log('let VALUES = ' + JSON.stringify(VALUES))

let COMMANDS = {}
KEYS.forEach(key => {
  COMMANDS[key] = key
})

console.log('let COMMANDS = ' + JSON.stringify(COMMANDS))

let declareSubset = function (sub, named) {
  let offsets = []
  Object.keys(sub).forEach(key => {
    offsets.push(KEYS.indexOf(key))
  })

  console.log('let ' + named + 'N = ' + JSON.stringify(offsets))
  let selection = ['let ' + named + 'Options = [']
  offsets.forEach(n => {
    selection.push(
      `{ label: VALUES[${n}], value: KEYS[${n}] },`,
    )
  })
  selection.push(']')
  console.log(selection.join('\n'))
}

declareSubset(old.prompt, 'prompt')
declareSubset(old.playback, 'playback')
*/
