export const ComputerSpeaking = {
  methods: {
    speakQuestion (field) {
      this.activeField = field.index
      this.playComputer(field.question + '?')
    },
    speakHint (field) {
      this.activeField = field.index
      this.playComputer(field.hint)
    },
    speakAnswer (field) {
      this.activeField = field.index
      this.playComputer(field.answer)
    },
    stopPlaying () {
      this.playQueue = []
    },
    speakAll () {
      this.fields.forEach(field => {
        if (this.show_index) {
          this.playQueue.push({
            field,
            script: 'Question ' + (field.index),
          })
        }
        if (this.show_questions) {
          this.playQueue.push({
            field,
            script: field.question,
          })
        }
        if (this.show_hints) {
          this.playQueue.push({
            field,
            script: field.hint,
          })
        }
        if (this.show_answers) {
          this.playQueue.push({
            field,
            script: field.answer,
          })
        }
      })
      this.playStack()
    },
    playComputer (text, notify) {
      window.speechSynthesis.cancel()
      if (notify) this.$q.notify(text)
      let vue = this
      let msg = new SpeechSynthesisUtterance((text))
      this.currentlyPlayingObject = msg
      msg.onend = function (e) {
        console.log('autoRecord playComputer done for ' + text)
        this.currentlyPlayingObject = false
        msg = false
        // do I need to avoid skipping?
        vue.playStack()
      }
      msg.addEventListener('end', function () {
        console.log('playComputer stopped second listener: ' + text)
      })

      console.log('autoRecord playComputer START')
      window.speechSynthesis.speak(msg)
    },
    canSpeak () {
      return ('speechSynthesis' in window)
    },
    playComputeURLQuestion (number) {
      let target = this.fields[number - 1]
      this.playComputer(target.question)
    },
    playComputeURLAnswer (number) {
      let target = this.fields[number - 1]
      this.playComputer(target.answer)
    },

    beep () {
      this.beepSound.play()
    },


  }
}
