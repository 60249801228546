import { Plan } from './Plan'
import gtm from 'src/components/gtm'
import RecorderService from '../shared/RecorderService'
import WebAudioPeakMeter from '../shared/WebAudioPeakMeter'
import { date } from 'quasar'

let webAudioPeakMeter1 = new WebAudioPeakMeter()

// destructuring to keep only what is needed
const {
  formatDate,
} = date

export const HTMLRecording = {
  data () {
    return {
      recordingFeedback: true, // currently ignored so nothing shifts visually
      recorderSrvc: false,
      peakMeterRawEl: false,
      micStatus: 'checking',
      practices: {
        URL: [],
        lesson: [],
        selection: [],
      },
    }
  },
  methods: {
    playRecording (ts) {
      let vue = this
      vue.latestRecording = vue.allRecordingsByTimestamp[ts]
      if (vue.latestRecording) {
        window.currentlyPlayingObject = vue.$refs.latestAudio
        vue.$nextTick(() => {
          window.currentlyPlayingObject.play()
        })
      }
      else vue.playStack()
    },
    lessonPlayPractice (ts) {
      let found = this.lessonPlan.byTS(ts)
      if (found) {
        found.playPractice(ts)
      } else {
        console.log('No plan matches ' + ts)
      }

      // let found = this.menu.findByKey(ts)
      // if (found) {
      //   found.recordings.forEach(rec => {
      //
      //     if (this.choiceFeedback !== 'practice') this.playQueue.push(rec.regionID)
      //     if (this.choiceFeedback !== 'lesson') this.playQueue.push(rec.ts)
      //     // add answer if computer
      //     if (this.computerGenerated) this.playQueue.push(rec.regionID * -1)
      //   })
      //
      //   this.playStack()
      // }
    },
    ensureIOSunlocked () {
      /*
      As far as I understand, if the audio element gets played through a direct click it will work
      */
      if (!this.safariPlayAlreadyTriggered) {
        console.log('Play Attempted for IOS issues')
        this.latestRecording = {}
        // the audio component does not yet have a sound attached.
        this.$refs.latestAudio.play()
        this.$refs.latestAudio.pause() // otherwise will play once the very first sound file is loaded
        this.safariPlayAlreadyTriggered = true
      }
    },

    URLPractice_UI (field) {
      if (this.micStatus !== 'found' && this.micStatus !== 'cordova') {
        alert('No Microphone available')
        return false
      }
      gtm.logEvent('regions', 'regionPractice_UI',
        'regionPractice_UI:' + this.lessonPlan.active.id, false)

      this.$store.commit('tagKey', field.answer)
      this.ensureIOSunlocked()
      let vue = this
      this.recordingFeedback = true

      try {
        this.recorderSrvc.startRecording().then(() => {
          vue.recordingInProgress = true
        }).catch((error) => {
          console.error('Exception while start recording: ' + error)
          this.$q.notify('Exception while start recording: ' + error.message)
        })
      } catch (e) {
        console.dir(e)
      }
      // this.maybeNextTourStop()
    },
    async URLEndThisRecording () {
      // if (process.env.MODE === 'cordova') {
      //   await cordovaStuff.stopCapture()
      //
      //   console.dir(cordovaStuff.audios)
      //   vue.latestRecording = {
      //     blobUrl: cordovaStuff.audios[0].src,
      //     ts: new Date().getTime()
      //   }
      //
      //   vue.$refs.latestAudio.play()
      //   vue.allRecordingsByTimestamp[vue.latestRecording.ts] = vue.latestRecording
      //
      //   // window.audioinput.stop((url, testing) => {
      //   //   vue.latestRecording = {
      //   //     blobUrl: testing,
      //   //     ts: new Date().getTime()
      //   //   }
      //   //   vue.allRecordingsByTimestamp[vue.latestRecording.ts] = vue.latestRecording
      //   // })
      //   this.recordingFeedback = false
      //   return
      // }
      gtm.logEvent('regions', 'regionPracticeEndRecording_UI', 'regionPracticeEndRecording_UI', false)

      this.recorderSrvc.stopRecording()
      this.recordingInProgress = false

      this.meterNodeRaw.disconnect()
      this.meterNodeRaw = null
      this.peakMeterRawEl.innerHTML = ''

      this.recordingFeedback = false

      // this.maybeNextTourStop()
    },
    URLQuitLessonPlan () {
      gtm.logEvent('lessonplan', 'quit', 'lessonPracticeQuit_UI', false)
      this.lessonPlan.recordings = []
      this.lessonPlan.treeNode.recordings = false
      // this.practices.URL.pop()
      this.$store.commit('rejectCurrentRecording')
      this.URLEndLessonPlan()
      if (this.practiceRecordingsExist) {
        this.currentTheme = false
      }
    },

    URLEndLessonPlan () {
      gtm.logEvent('lessonplan', 'end', 'lessonPracticeQuit_UI', false)

      // expand to the tree node so user can click
      let targetID = this.dated(this.lessonPlan.TS)

      this.selected = targetID

      this.lessonPlan.treeNode.recordings = this.lessonPlan.recordings

      this.lessonPlan = new Plan()

      // this.leftSlideBarOpen = true
    },
    URLReplayBeforeInput () {
      gtm.logEvent('lessonPlan', 'play', 'lessonPlayActiveLesson_UI', false)

      if (this.playQueue.length) {
        this.playStack()
      }
      else {
        this.lessonPlan.active.play()
      }
    },

    onNewRecording (evt) {
      /*
        Processes local recording
          - for one region
          - for one region in context of an entire set of lessons called a lessonPlan
          - as a lesson itself
        Usually has an activeRegion, and uses that id to find the tree to add this practice under
        May be also a part of a lesson plan - this.lessons.list
        inLessonPlan mode: this.lessons.TS is the global id

        lessonBeingRecorded means a lesson itself
      */

      let recording = evt.detail.recording

      recording.TS = recording.ts // library comes as .ts, I always use .TS

      let inLessonPlan = !!(this.lessonPlan.TS)
      if (inLessonPlan) {
        recording.lessonTS = this.lessonPlan.TS
        recording.fromPlanStep = this.lessonPlan.index
        this.lessonPlan.recordings.push(recording)
      }

      this.$store.dispatch('recorded', recording)

/*
      if (this.format.length > 0) {
        this.fields[this.lessonPlan.index].recordings[this.lessonPlan.TS] = recording.ts
      }
*/
      this.fields[this.lessonPlan.index].recordings[this.lessonPlan.TS] = recording.ts

      this.latestRecording = recording
      // this.allRecordingsByTimestamp[recording.ts] = recording

      console.log('autoRecord // I am in a lesson plan')

      this.lessonsNext()

      if (!this.lessonPlan.completed) {

        this.lessonPlan.active.play() // this is the next prompt
      } else {

        // The part of the prompt after recording may have a little to play yet
        if (this.playQueue.length > 0) {
          this.playStack()
        }
        // no more lessons to play
        // this.leftSlideBarOpen = true
        this.URLEndLessonPlan()
      }

    },

    dated (date) {
      return formatDate(date, 'MMM DD @ h:mm ')
    },













    lessonsNext: function () {
      this.lessonSetOrder(this.lessonPlan.index + 1)
    },

    URLPlaybackEnded () {

      console.log('autoRecord <audio> done')
      if (window.jim_DEBUG_FULL) console.log('URLPlaybackEnded')

      console.log('autoRecord region Practice Playback Ended')
      this.playStack()
    },
  },
  created () {
  },
  mounted () {
    this.peakMeterRawEl = document.getElementById('peak-meter-raw') || this.$refs.feedbackMeter
    if (!this.peakMeterRawEl) {
      alert('No meter feedback found')
    }
    let vue = this
    vue.recorderSrvc = new RecorderService()
    vue.recorderSrvc.em.addEventListener('recording', (evt) => vue.onNewRecording(evt))
    vue.recorderSrvc.onGraphSetupWithInputStream = (inputStreamNode) => {
      if (window.jim_DEBUG_FULL) console.log('vue.recorderSrvc.onGraphSetupWithInputStream')

      vue.meterNodeRaw = webAudioPeakMeter1.createMeterNode(inputStreamNode, vue.recorderSrvc.audioCtx)
      webAudioPeakMeter1.createMeter(vue.peakMeterRawEl, vue.meterNodeRaw, {})
    }
  }
}
