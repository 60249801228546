// import assert from 'assert'

export class Menu {
  static all = {}

  static UI

  static interfaces (UI) {
    Menu.UI = UI
  }
  static nodes = []
  static counts = {
    regions: 0,
    practices: 0,
  }

  static fixedItems
  static appendKey = 'regionsKey'

  constructor (label, icon, key) {
    this.label = label
    this.icon = icon || ''
    this.key = key || label
    this.children = []
    this.noTick = true
    this.parent = false
    Menu.all[this.key] = this
    this.next = false
    this.previous = false
  }

  static regionDest () {
    return Menu.nodes[1]
  }

  static append (key, child, reveal) {
    // TODO: used when removing regions, can set once after menu is finished
    Menu.appendKey = Menu.appendKey || key // only captures first key, but that's all we use
    let parentMenu = Menu.all[key]
    Menu.fixedItems = Menu.fixedItems || parentMenu.children.length

    child.parent = parentMenu
    parentMenu.children.push(child)
    child.hookup()
    // Menu.peerNodes[1].children.push(child)
    if (reveal) child.reveal()
    return child
  }

  static topLevel (menu) {
    // puts myself within structure
    Menu.nodes.push(menu)
    Menu.UI.peerNodes.push(menu)
    return menu
  }

  static get nodesJSON () {
    return JSON.stringify(Menu.nodes, function (key, value) {
      if (key === 'parent') {
        return
      }
      return value
    })
  }

  get and () {
    return this.parent || this
  }

  static clear () {
    Menu.nodes = []
    Menu.UI.peerNodes = []
  }

  contains (menu) {
    menu.parent = this
    this.children.push(menu)
    return menu
  }

  static clearFeedback () {

    while (Menu.UI.expanded.length) Menu.UI.expanded.pop()
    Menu.UI.selected = false
    while (Menu.UI.ticked.length) Menu.UI.ticked.pop()
  }

  static clearRegions () {
    // Delete all that are DrillMenu

    Menu.all[Menu.appendKey].children = Menu.all[Menu.appendKey].children.slice(0, Menu.fixedItems)
  }

  reveal () {
    // while (Menu.UI.expanded.length) Menu.UI.expanded.pop()

    Menu.UI.expanded.push(this.key)
    let parent = this.parent
    while (parent) {
      Menu.UI.expanded.push(parent.key)
      parent = parent.parent
    }

    Menu.UI.selected = this.key
  }

  static findByKey (key) {
    return Menu.all[key]
  }

  hookup () {
    // menu items don't care, only data elements
  }
}

export class MenuAction extends Menu {
  constructor (label, icon, handler, key) {
    super(label, icon, key)
    this.handler = handler
  }
}

export class MenuRegion extends Menu {
  constructor (region, label, icon, handler, key, colorQuasar, noTick) {

    super(label, icon, key)
    this.handler = handler
    this.colorQuasar = colorQuasar
    this.header = 'region'
    this.noTick = noTick
    Menu.counts.regions += 1
    this.logLatestPosition()

    this.region = region
    this.start = region.start
    this.end = region.end
    this.start0 = region.start
    this.end0 = region.end
  }
  logLatestPosition () {
    this.start0 = this.start
    this.end0 = this.end
  }

  changed (region) {
    if ((this.start !== region.start) || (this.end !== region.end)) {
      this.start = region.start
      this.end = region.end
      this.region = region
      return true
    }
    else return false
  }

  earlier (delta) {
    this.start += (delta || 0.1)
    this.adjustNeighbors()
  }

  later (delta) {
    this.start -= (delta || 0.1)
    this.adjustNeighbors()
  }

  longer (delta) {
    this.end += (delta || 0.1)
    this.adjustNeighbors()
  }

  shorter (delta) {
    this.end -= (delta || 0.1)
    this.adjustNeighbors()
  }

  adjustNeighbors () {

    if (this.start !== this.start0) {
      // where they previously close?
      if (this.previous && (Math.abs(this.previous.end - this.start0) < 0.1)) {
        this.previous.end = this.start
        this.previous.region.update({
          end: this.start
        })
        this.start0 = this.start
      }
    }
    if (this.end !== this.end0) {
      if (this.next && (Math.abs(this.next.start - this.end0) < 0.1)) {
        this.next.start = this.end
        this.next.region.update({
          start: this.end
        })
        this.end0 = this.end
      }
    }
  }

  remove () {

    let myOffset = this.parent.children.indexOf(this)
    if (myOffset < 0) return false
    this.parent.children.splice(myOffset, 1)
    Menu.counts.regions -= 1
  }

  hookup () {
    let mom = this.parent
    if (mom.children.length > 1) {
      let latestKid = mom.children[mom.children.length - 2] // This one is already joined at -1
      // eslint-disable-next-line valid-typeof
      if (latestKid instanceof MenuRegion) {
        latestKid.next = this
        this.previous = latestKid
      }
    }
  }

}


export class MenuPractice extends Menu {
  constructor (label, handler, key, noTick, isLesson) {
    // overrides icon, colorQuasar
    super(label, 'hearing', key)
    this.handler = handler
    this.colorQuasar = isLesson ? 'primary' : 'secondary'
    this.header = 'region'
    this.noTick = noTick
    Menu.counts.practices += 1
  }

  remove () {

    let myOffset = this.parent.children.indexOf(this)
    if (myOffset < 0) return false
    this.parent.children.splice(myOffset, 1)
    Menu.counts.practices -= 1
  }
}

/*

let noop = function () {
  console.log('No Operation')
}
*/
