import { PlanURL } from './Plan'

export const ComputerPrompting = {
  methods: {
    practiceAll_UI (target) {
      let key
      switch (target) {
        case 'questions': {
          key = 'questions'
          break
        }
        case 'hints': {
          key = 'hints'
          break
        }
        case 'answers': {
          key = 'answers'
          break
        }
        case 'custom': {
          key = 'default'
          break
        }
      }

      key += '_' + (this.recording || 'click')
      this.prompts = this.PRESETS.prompts[key]

      this.lessonBeingRecorded = false

      this.lessonPlan = new PlanURL(this.$data, this.$store.state, 'fields', 'prompts', 'playback', 'customText1', 'customText2')

      this.timestamps.push(this.lessonPlan.TS)
      this.setup_plan(this.$store)
    },
  },
}
